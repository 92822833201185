import { onMounted, onBeforeUnmount } from 'vue';

export default function useDataGridHover(pOptions: {
    viewportRef: Ref<HTMLElement>,
    rowClass: string,
    isTable?: boolean,
    onHover?: (pRowIndex: number) => {},
    onHoverLeave?: (pRowIndex: number) => {},
}) {

    let previousIndex: string | undefined = undefined;
    let previousNodes: HTMLElement[] = [];

    let rowClass = pOptions.rowClass ?? 'o365-body-row';

    function removePrevious(pSkipEvent?: boolean) {
        if (previousNodes.length > 0) {
            previousNodes.forEach(el => el.classList.remove('row-hover'));
            previousNodes = [];
            if (pOptions.onHoverLeave && !pSkipEvent) { pOptions.onHoverLeave(+previousIndex!); }
        }
    }

    function rowQuery(previousIndex?: string, isInNewRecords = false) {
        if (pOptions.isTable) {
            return isInNewRecords
                ? `div[data-o365-container="N"] .${rowClass}[data-o365-rowindex="${previousIndex}"]`
                : `.${rowClass}[data-o365-rowindex="${previousIndex}"]:not([data-o365-container="N"] .${rowClass})`;
        } else {
            return `.${rowClass}[data-o365-rowindex="${previousIndex}"]`;
        }
    }

    const onMouseOver = (e: MouseEvent) => {
        const target = <HTMLElement>e.target;
        window.requestAnimationFrame(() => {
            if (target == null) { return; }
            const vClosest: HTMLElement | null = target.closest(`.${rowClass}`);
            if (vClosest) {
                const newIndex = vClosest.dataset.o365Rowindex;
                if (previousIndex != newIndex) { removePrevious(); }
                previousIndex = newIndex;
                if (pOptions.viewportRef.value == null) { return; }
                let rowsSelector = '';
                if (pOptions.isTable) {
                    const isInNewRecords = target.closest('[data-o365-container="N"]') != null;
                    rowsSelector = rowQuery(previousIndex, isInNewRecords);
                } else {
                    rowsSelector = rowQuery(previousIndex);
                }
                pOptions.viewportRef.value.querySelectorAll<HTMLElement>(rowsSelector).forEach((element: HTMLElement) => {
                    previousNodes.push(element);
                    element.classList.add('row-hover');
                    if (pOptions.onHover) { pOptions.onHover(+previousIndex!); }
                });
            } else {
                removePrevious();
            }

        });
    }


    /** Set row as the currently hovered one */
    function setRowHover(pIndex?: number | null) {
        if (pIndex == null) {
            removePrevious(true);
        } else {
            if (`${previousIndex}` != `${pIndex}`) { removePrevious(); }
            previousIndex = `${pIndex}`;
            if (pOptions.viewportRef.value == null) { return; }
            let rowsSelector = '';
            if (pOptions.isTable) {
                const isInNewRecords = pIndex < 0;
                rowsSelector = rowQuery(previousIndex, isInNewRecords);
            } else {
                rowsSelector = rowQuery(previousIndex);
            }
            pOptions.viewportRef.value.querySelectorAll<HTMLElement>(rowsSelector).forEach((element: HTMLElement) => {
                previousNodes.push(element);
                element.classList.add('row-hover');
            });
        }
    }

    const onMouseLeave = (_e: MouseEvent) => {
        window.requestAnimationFrame(() => {
            removePrevious();
        });
    }

    onMounted(() => {
        pOptions.viewportRef.value.addEventListener('mouseover', onMouseOver)
        pOptions.viewportRef.value.addEventListener('mouseleave', onMouseLeave)
    });

    onBeforeUnmount(() => {
        pOptions.viewportRef.value.removeEventListener('mouseover', onMouseOver)
        pOptions.viewportRef.value.removeEventListener('mouseleave', onMouseLeave)
    });

    return { setRowHover };
}

type Ref<T> = {
    value: T
};
